import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb, Card, Container, Header, Icon, Item, List, Label } from "semantic-ui-react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import logo from "../images/progmiscon-logo-64.png"

function TypeUnknown({ title, authors, date, url, abstract }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

function ConferencePaper({ title, authors, date, url, abstract, proceedingsTitle }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            <p>
              {proceedingsTitle}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}


function Thesis({ title, authors, date, url, abstract, thesisType, university, place }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p>
              {thesisType} - {university} - {place}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

function JournalArticle({ title, authors, date, url, abstract, publication, volume, issue, pages }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            <p>
              {publication}, {volume}, {issue} {pages ? `, ${pages}` : null}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

function Report({ title, authors, date, url, abstract, reportType, reportNumber, seriesTitle }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p>
              {reportType || "Report"}, {reportNumber}, {seriesTitle}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

function WebPage({ title, authors, date, url, abstract, websiteTitle, accessed }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            <p>
              <a href={url} target="_blank" rel="noreferrer">{websiteTitle}</a> - Last accessed: {accessed}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

function BookSection({ title, authors, date, url, abstract, bookTitle, publisher, pages }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Description>
            <p>
              {date.substring(0,4)}
            </p>
            <p style={{fontSize: '140%'}}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {title}
                </a>
              ) : (
                <>
                  {title}
                </>
              )}
            </p>
            <p>
              {bookTitle}, {publisher}, {pages}
            </p>
            {abstract ? (<p>{abstract}</p>): null}
            Authors:
            {authors.map((author) => (
              <Card
                key={`${author.firstName}-${author.lastName}`}
              >
                <Card.Content
                  verticalAlign="middle"
                >
                  <Card.Header>
                    {author.firstName} {author.lastName}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
          </Item.Description>
        </Item.Content>
      </Item>
    </>
  );
}

const getReferenceComponent = (reference) => {
  if(!reference || !reference.data) {
    return (
      <>
        Reference not found
      </>
    )
  }

  const { itemType } = reference.data

  switch(itemType) {
    case "bookSection":
      return (
        <BookSection
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          bookTitle={reference.data.bookTitle}
          publisher={reference.data.publisher}
          pages={reference.data.pages}
        />
      )
    case "webpage":
      return (
        <WebPage
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          websiteTitle={reference.data.websiteTitle}
          accessed={reference.data.accessDate}
        />
      )
    case "report": 
      return (
        <Report
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          reportType={reference.data.reportType}
          reportNumber={reference.data.reportNumber}
          seriesTitle={reference.data.seriesTitle}
        />
      )
    case "thesis": 
      return (
        <Thesis
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          thesisType={reference.data.thesisType}
          university={reference.data.university}
          place={reference.data.place}
        />
      )
    case "journalArticle": 
      return (
        <JournalArticle
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          publication={reference.data.publicationTitle}
          volume={reference.data.volume}
          issue={reference.data.issue}
          pages={reference.data.numPages}
        />
      )
    case "conferencePaper": 
      return (
        <ConferencePaper
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
          proceedingsTitle={reference.data.proceedingsTitle}
        />
      )
    default: 
      return (
        <TypeUnknown
          title={reference.data.title}
          date={reference.data.parsedDate}
          authors={reference.data.creators}
          url={reference.data.url}
          abstract={reference.data.abstractNote}
        />
      )
  }
}

const ReferencePageTemplate = ({ data }) => {
  const reference = data.zoteroItems
  const referenceCount = data.allMdx.edges ? data.allMdx.edges.length : 0;

  const refEntry = data.refs.edges.filter(edge => edge.node.slug === "references/"+reference.citationKey);

  return (
    <Layout>
      <SEO
        title={reference.cirationKey}
      />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/references">
            References
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{reference.citationKey}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="bookmark" />
          <Header.Content>
            {reference.citationKey}
          </Header.Content>
        </Header>
        
        {getReferenceComponent(reference)}

        {refEntry.map((edge) => (
          <>
            <Header as="h2" dividing>
              <Header.Content>
                Study Information
              </Header.Content>
              <Header.Subheader>
                Manually extracted from the paper by the Progmiscon.org team
              </Header.Subheader>
            </Header>

            <Header as="h3">
              <Header.Content>
                Programming Languages
              </Header.Content>
            </Header>

            <p>
              {edge.node.frontmatter.langs.map(lang => (
                <Label
                  key={lang}
                  color="red"
                >
                { lang }
                </Label>
              ))}
            </p>

            <Header as="h3">
              <Header.Content>
                Method
              </Header.Content>
            </Header>
            <p>{edge.node.frontmatter.method}</p>

            <Header as="h3">
              <Header.Content>
                Subjects
              </Header.Content>
            </Header>
            <p>{edge.node.frontmatter.subjects}</p>

            {
              edge.node.frontmatter.artifact ? (
                <>
                  <Header as="h3">
                    <Header.Content>
                      Artifact
                    </Header.Content>
                  </Header>

                  <a href={edge.node.frontmatter.artifact}>{edge.node.frontmatter.artifact}</a>
                </>
              ) : null
            }
          </>
        ))}

        <Header as="h2" dividing>
          <Header.Content>
            Related Misconceptions
          </Header.Content>
          <Header.Subheader>
            Referenced by {referenceCount} Misconception{referenceCount === 1 ? "" : "s"}
          </Header.Subheader>
        </Header>

        <List selection verticalAlign="middle">
          {data.allMdx.edges.map((edge) => (
            <List.Item as={Link} to={`/${edge.node.slug}`} key={edge.node.slug}>
              <img
                className="ui image"
                alt="Misconception"
                width="20"
                src={logo}
              />
              <List.Content>
                <List.Header>
                  {/* color this like a link, without producing a nested a element */}
                  <span style={{ color: "#4183c4" }}>{edge.node.fields.name}</span>
                </List.Header>
                {edge.node.frontmatter.shortDescription}
              </List.Content>
            </List.Item>
          ))}
        </List>

      </Container>
    </Layout>
  )
}

export default ReferencePageTemplate

export const query = graphql`
  query($citationKey: String!) {
    zoteroItems(citationKey: {eq: $citationKey}) {
      citationKey
      data {
        key
        version
        itemType
        title
        abstractNote
        date
        proceedingsTitle
        conferenceName
        place
        publisher
        volume
        pages
        series
        language
        DOI
        ISBN
        shortTitle
        url
        accessDate(formatString: "YYYY.MM.DD")
        archive
        archiveLocation
        libraryCatalog
        callNumber
        rights
        extra
        dateAdded
        dateModified
        reportNumber
        reportType
        seriesTitle
        institution
        publicationTitle
        issue
        seriesText
        journalAbbreviation
        ISSN
        bookTitle
        seriesNumber
        numberOfVolumes
        edition
        creators {
          creatorType
          firstName
          lastName
        }
        tags {
          tag
          type
        }
        parsedDate,
        creatorSummary,
      }
    }
    allMdx(filter: {frontmatter: {references: {elemMatch: {citationKey: {eq: $citationKey}}}}}) {
      edges {
        node {
          id
          slug
          fields {
            name
          }
          frontmatter {
            shortDescription
          }
        }
      }
    }
    refs: allMdx(filter: {frontmatter: {type: {eq: "ref"}}}) {
      edges {
        node {
          id
          slug
          frontmatter {
            langs
            method
            subjects
            artifact
          }
          fields {
            slug
            name
            pl
          }
        }
      }
    }
  }
`
